export default {
  "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
  "OOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups"])},
  "UPLOAD": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger votre"])},
    "CAPTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "COUNT": {
      "SINGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document téléchargé"])},
      "PLURAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documents téléchargés"])}
    },
    "DOCUMENTS_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos documents ont été téléchargés avec succès"])},
    "MAX_UPLOAD_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille maximale autorisée des documents: "])},
    "MISSING_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manque de documents"])},
    "CONTINUE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger à nouveau"])},
    "MAX_FILES_LENGTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximal de fichiers autorisés par téléchargement: "])}
  },
  "DOCUMENT_TYPE": {
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé d'identité bancaire"])},
    "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé de comptes"])},
    "DISABLED_WORKER_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnaissance de la qualité de travailleur handicapé"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis de conduire"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité (recto et verso)"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité (recto et verso)"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
    "PROOF_OF_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificatif de domicile"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de séjour"])},
    "SIGNED_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
    "SOCIAL_SECURITY_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation de sécurité sociale"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis d'imposition"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
    "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la liasse fiscale"])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulletin de paie"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])}
  },
  "TOAST": {
    "ANALYZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des fichiers en cours"])},
    "ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers analysés"])},
    "ANALYZE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est arrivée lors de l'analyse, veuillez réessayer"])},
    "CONFIGURATION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a échouée"])},
    "TOO_LARGE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier trop volumineux"])},
    "UPLOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi en cours"])},
    "UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi terminé"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'envoi"])},
    "SEND_OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi à l'analyse"])},
    "OCR_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi à l'analyse terminé"])},
    "OCR_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'envoi à l'analyse"])},
    "TOO_MANY_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de fichiers téléchargés, le maximum est de 5 fichiers"])},
    "TOO_MANY_FILES_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de fichiers téléchargés pour la pièce d'identité, le maximum est de 2 fichiers"])}
  },
  "ERROR": {
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue"])},
    "BAD_GATEWAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La communication avec le serveur a échouée"])},
    "UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à télécharger des documents"])},
    "NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page est introuvable"])}
  }
}